import { checkAccessTokenStatus, useAccessToken } from '~/composables/auth'
import { fetchFrontendState } from '~/composables/frontendState'
import { fetchTeamMembers } from '~/composables/teamMembers'
import { fetchFavoriteTags } from '~/composables/tags'

export default defineNuxtRouteMiddleware(async () => {
  if (process.server) {
    return
  }

  await checkAccessTokenStatus()

  const accessToken = useAccessToken()
  if (!accessToken.value) {
    return navigateTo('/login')
  }

  fetchFrontendState()
  fetchTeamMembers()
  fetchFavoriteTags()
})
